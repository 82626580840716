<template>
  <div>
    <!-- picC210FD248352D987DCB1889212C7634E.jpg -->
    <img class="img-small leftimage rounded" src="@/assets/news-network/lu-launch-update.jpg">
    <p>
      The LEGO Group has an exciting vision for our first massively multiplayer online game (MMOG). We’re aiming not just to make a virtual world, but to co-create an entire LEGO Universe® with fans like you from around the world. We have communicated and shared creative ideas with fans since the very beginning of the game’s development. This unique approach is working even better than we originally imagined.
    </p>
    <p>
      The LEGO Universe team has decided to delay the game’s launch so that it gets the full attention of our company and fans when it makes its public debut. We have a ton of cool new products planned for release in 2009, and want to continue developing the game to make sure that LEGO Universe becomes a truly great game and LEGO experience.
    </p>
    <p>
      We believe that we are building a LEGO Universe worth waiting for, and appreciate your patience – and help – as the game comes to life. Your support is clear – from the art, ideas and models you contribute to the LEGO Creation Lab, to insightful and interesting comments on the message boards, you continue to inspire our development team as they construct a truly original MMOG.
    </p>
    <p>
      LEGO Universe’s development is coming along very well, and as you will see from the latest images included here, there is a lot of fun and adventure brewing! The good news is that we now have more time to make LEGO Universe even better for you.
    </p>
    <p>
      In the meantime, you can keep your building skills sharp and continue to enjoy your favorite LEGO activities, games and challenges on My LEGO Network, LEGO.com and our console games LEGO® <i>Star Wars</i>™, LEGO® Batman™ and LEGO® Indiana Jones™.
    </p>
    <p>
      One of our goals is for LEGO Universe to deliver nothing less than the ultimate online play experience. A guiding motto coined by the LEGO Group’s founder, Ole Kirk Christiansen, more than 75 years ago: “Only the best is good enough.”
    </p>
    <!-- picC19E5D52536359A55D8F4382EEAD3F19.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/lu-launch-update-2.jpg">
    <p><i>LEGO Universe</i> - Adventure!</p>

    <!-- picD031087CE8EDD4B6B985A878DC56694C.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/lu-launch-update-3.jpg">
    <p><i>LEGO Universe</i> - Ninjas!</p>

    <!-- picF0EBEACBBE93DEDA87A8C6D3704516C4.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/lu-launch-update-4.jpg">
    <p><i>LEGO Universe</i> - Pirates!</p>
  </div>
</template>
